import React, { Dispatch, SetStateAction } from 'react';
import * as S from '../RegisterAcademicProject/styles';
import QuillEditor from '../../../../../../../../InputTextQuill';
import { AlertWarning } from '../../../../../../../../Alert';
import { IFile } from '../RegisterAcademicProject';
import FieldFormik from '../../../../../../../../FieldFormik';

interface Props {
  student?: string;
  date: string;
  isTcc?: boolean;
  type: number;
  subject?: string;
  textObservation: string;
  setTextObservation: Dispatch<SetStateAction<string>>;
  finalReport: IFile;
  setFinalReport: Dispatch<SetStateAction<IFile>>;
  mandatoryAttachment: IFile;
  setMandatoryAttachment: Dispatch<SetStateAction<IFile>>;
  fileTcc: IFile;
  setFileTcc: Dispatch<SetStateAction<IFile>>;
  setTitleTcc: Dispatch<SetStateAction<string>>;
}

export default function InfoListAcademicProject({
  student,
  date,
  subject,
  textObservation,
  setTextObservation,
  finalReport,
  setFinalReport,
  mandatoryAttachment,
  setMandatoryAttachment,
  isTcc,
  setTitleTcc,
  fileTcc,
  setFileTcc,
  type,
}: Props) {
  const handleFirstItemFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setMandatoryAttachment({
        file: file,
        name: file.name,
      });
    } else {
      event.target.value = '';
      setMandatoryAttachment({
        file: undefined,
        name: '',
      });
    }
  };
  const handleFileChangeTcc = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileTcc({
        file: file,
        name: file.name,
      });
    } else {
      event.target.value = '';
      setFileTcc({
        file: undefined,
        name: '',
      });
    }
  };

  const handleSecondItemFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setFinalReport({
        file: file,
        name: file.name,
      });
    } else {
      event.target.value = '';
      setFinalReport({
        file: undefined,
        name: '',
      });
    }
  };

  return (
    <S.ContainerInfo>
      {isTcc ? (
        type !== 3 && (
          <>
            <FieldFormik
              name="finalReport"
              onChange={({ target }) => {
                setTitleTcc(target.value);
              }}
              type="string"
              label="Título do TCC (Sem abreviações - O Título informado constará no certificado) "
              widthSize="fullWidth"
              notLimitHeight
            />
          </>
        )
      ) : (
        <>
          <S.KeyValue>
            <S.Key>Aluno: </S.Key> <S.Value> {student}</S.Value>
          </S.KeyValue>
          <S.KeyValue>
            <S.Key>Entrada: </S.Key> <S.Value> {date}</S.Value>
          </S.KeyValue>
          <S.KeyValue>
            <S.Key>Tema: </S.Key>{' '}
            <S.Value> {subject ?? 'Estágio Supervisionado'}</S.Value>
          </S.KeyValue>
        </>
      )}
      <S.Key>{type !== 3 ? 'Observação: ' : 'Justificatica: '}</S.Key>
      <QuillEditor setText={setTextObservation} text={textObservation} />

      {!isTcc ? (
        <>
          <S.Key>
            {type !== 8 ? 'Documentação Obrigatória' : 'Declaração '}
          </S.Key>
          <S.ContainerFile>
            <S.InputFile>
              <span>
                {mandatoryAttachment.name
                  ? 'Substituir arquivo'
                  : 'Escolher arquivo'}
              </span>
              <input
                type="file"
                className="input-file-first-item"
                onChange={handleFirstItemFileChange}
              />
            </S.InputFile>
            <S.Text>
              {mandatoryAttachment.name
                ? mandatoryAttachment.name
                : 'Nenhum arquivo escolhido.'}
            </S.Text>
          </S.ContainerFile>
          <S.Key>
            {type !== 8
              ? 'Relatório Final'
              : 'Contracheque / Contagem de Tempo / Contrato'}{' '}
          </S.Key>
          <S.ContainerFile>
            <S.InputFile>
              <span>
                {finalReport.name ? 'Substituir arquivo' : 'Escolher arquivo'}
              </span>
              <input
                type="file"
                className="input-file-second-item"
                onChange={handleSecondItemFileChange}
              />
            </S.InputFile>

            <S.Text>
              {finalReport.name
                ? finalReport.name
                : 'Nenhum arquivo escolhido.'}
            </S.Text>
          </S.ContainerFile>
        </>
      ) : (
        type !== 3 && (
          <>
            <S.Key>Arquivo: </S.Key>
            <S.ContainerFile>
              <S.InputFile>
                <span>
                  {fileTcc.name ? 'Substituir arquivo' : 'Escolher arquivo'}
                </span>
                <input
                  type="file"
                  className="input-file-first-item"
                  onChange={handleFileChangeTcc}
                />
              </S.InputFile>
              <S.Text>
                {fileTcc.name ? fileTcc.name : 'Nenhum arquivo escolhido.'}
              </S.Text>
            </S.ContainerFile>
          </>
        )
      )}
      {type !== 3 && (
        <AlertWarning title="Arquivos (Tamanho máximo de upload: 8Mb)." />
      )}
    </S.ContainerInfo>
  );
}
