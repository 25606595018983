import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';

import ErrorBoundary from '../../common/ErrorBoundary';
import { useLocation, useNavigate } from 'react-router-dom';
import { authNewStudentService } from '../../../services/auth';
import { IDataStudent } from '../../../interfaces/newStudent/dataStudent';
import {
  homeServiceNewStudent,
  myDataServiceNewStudent,
} from '../../../services/newStudent';
import {
  contrast,
  dark,
  gray,
  light,
} from '../../../styles/GlobalStyle/themes';
import {
  contractServiceNewStudent,
  IDataUnsignedContracts,
} from '../../../services/newStudent/contract/contract.service';
import { IAdvertising } from '../Modal/ModalVideo';
import { addDataCache, getDataCache } from '../../../util/common/appUtil';

export interface INewStudentProvider {
  student: IDataStudent;
  setStudent: Dispatch<SetStateAction<IDataStudent>>;
  loading: boolean;
  setIsLoading: (param: boolean) => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: Dispatch<SetStateAction<boolean>>;
  themeSelected: 'dark' | 'contrast' | 'gray' | 'light';
  setThemeSelected: Dispatch<
    SetStateAction<'dark' | 'contrast' | 'gray' | 'light'>
  >;
  unsignedContracts: IDataUnsignedContracts[];
  setUnsignedContracts: Dispatch<SetStateAction<IDataUnsignedContracts[]>>;
  accessedAs: boolean;
  setAccessedAs: Dispatch<SetStateAction<boolean>>;
  videoShow: IAdvertising | undefined;
  setVideoShow: Dispatch<SetStateAction<IAdvertising | undefined>>;
}

export const NewStudentProviderContext = createContext<INewStudentProvider>(
  {} as INewStudentProvider,
);

export const themes = {
  dark: dark,
  contrast: contrast,
  gray: gray,
  light: light,
};

export default function NewStudentProvider({ children }) {
  const [loading, setLoading] = useState(false);
  const [accessedAs, setAccessedAs] = useState(false);
  const [videoShow, setVideoShow] = useState<IAdvertising | undefined>({
    id: 0,
    url: '',
  });

  const location = useLocation();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [student, setStudent] = useState<IDataStudent | undefined>();
  const [themeSelected, setThemeSelected] = useState<
    'dark' | 'contrast' | 'gray' | 'light'
  >(student?.preferences.theme ?? 'light');
  const { pathname } = useLocation();

  const [unsignedContracts, setUnsignedContracts] =
    useState<IDataUnsignedContracts[]>();

  const arrayLoading: boolean[] = [];

  const setIsLoading = (param: boolean) => {
    arrayLoading.push(param);
    const booleanTrue = arrayLoading.filter((t) => t === true).length;
    const booleanFalse = arrayLoading.filter((t) => t === false).length;

    if (booleanTrue === booleanFalse) {
      return setLoading(false);
    } else return setLoading(true);
  };

  const handleCheckPersonalInformation = () => {
    const contract = localStorage.getItem('unsignedContracts');
    const accessed = !!localStorage.getItem('accessedAs');
    setAccessedAs(accessed);
    console.log(!!student?.dataStudent.name, accessed);

    if (student?.dataStudent.name && !accessed) {
      if (student.dataStudent.shouldCheckPersonalInformation) {
        return navigate('/new/aluno/conferencia-dados');
      } else if (contract && contract === 'true') {
        return navigate('/new/aluno/contratos');
      }
    } else if (
      location.pathname.includes('conferencia-dados') ||
      location.pathname.includes('contratos')
    ) {
      return navigate('/new/aluno/inicio');
    }
  };
  useEffect(() => {
    handleCheckPersonalInformation();
  }, [loading, student, unsignedContracts]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!student) return;

    const load = async () => {
      const tmpDataStudent = JSON.parse(localStorage.getItem('dataStudent')!);
      if (
        tmpDataStudent?.preferences &&
        tmpDataStudent?.preferences?.theme !== themeSelected
      ) {
        tmpDataStudent.preferences.theme = themeSelected;
        localStorage.setItem('dataStudent', JSON.stringify(tmpDataStudent));
      }
    };
    load();
  }, [themeSelected]);

  useEffect(() => {
    const validateUser = async () => {
      const isLoggedUser = await authNewStudentService.getLoggedUser();

      if (
        location.pathname === '/new/aluno' ||
        location.pathname === '/new/aluno/'
      ) {
        if (isLoggedUser) {
          navigate('/new/aluno/inicio');
        }
        return;
      }

      if (!isLoggedUser) {
        authNewStudentService.cleanLoggerUser();
        return;
      }

      handleCheckPersonalInformation();

      const loadStudentData = async () => {
        const storedDataStudent = localStorage.getItem('dataStudent');
        const dataStudent = storedDataStudent
          ? JSON.parse(storedDataStudent)
          : null;

        if (!dataStudent) {
          setIsLoading(true);
          try {
            const { data, result } = await homeServiceNewStudent.show();
            if (result) {
              if (
                data.preferences.isOldLayout &&
                data.preferences.canYouAccessOld
              )
                window.location.href = '/aluno/dashboard';

              setStudent(data);
              localStorage.setItem('dataStudent', JSON.stringify(data));

              if (data.dataStudent.shouldCheckPersonalInformation) {
                const dataStudent = await getDataCache(
                  'dataStudent',
                  '/new/aluno/dados',
                );
                if (!dataStudent) {
                  const studentData = await myDataServiceNewStudent.studentData(
                    {
                      login: data?.dataStudent.cpf
                        ? String(data?.dataStudent.cpf)
                        : String(data?.dataStudent.email),
                    },
                  );
                  if (studentData.result) {
                    const tmpData = {
                      name: studentData.data.name,
                      email: studentData.data.email,
                      cellPhone: studentData.data.cellPhone,
                      businessPhone: studentData.data.businessPhone,
                      homePhone: studentData.data.homePhone,
                      fatherName: studentData.data.fatherName,
                      motherName: studentData.data.motherName,
                      bornDate: studentData.data.bornDate,
                      rgId: studentData.data.rgId,
                      cpf: studentData.data.cpf,
                      raceColor: studentData.data.raceColor,
                      studentGraduation: studentData.data.studentGraduation,
                      degree: studentData.data.degree,
                      graduationYear: studentData.data.graduationYear,
                      nationality: studentData.data.nationality,
                      naturalness: studentData.data.naturalness,
                      university: studentData.data.university,
                      complement: studentData.data.complement,
                      city: studentData.data.city,
                      state: studentData.data.state,
                      street: studentData.data.street,
                      cep: studentData.data.cep,
                      number: studentData.data.number,
                      neighborhood: studentData.data.neighborhood,
                      addressCep: studentData.data.cep,
                      poleId: studentData.data.poleId,
                    };

                    addDataCache('dataStudent', tmpData, '/new/aluno/dados');
                  }
                }

                return navigate('/new/aluno/conferencia-dados');
              }

              const contractResponse =
                await contractServiceNewStudent.unsignedContracts();
              if (contractResponse.result) {
                setUnsignedContracts(contractResponse.data);
                if (contractResponse.data.length > 0) {
                  localStorage.setItem('unsignedContracts', 'true');
                } else {
                  localStorage.removeItem('unsignedContracts');
                }
              }
              setVideoShow(data.dataStudent.videos[0]);
            }
          } catch (error) {
            throw new Error(error);
          } finally {
            setIsLoading(false);
          }
        } else {
          if (
            dataStudent.preferences &&
            dataStudent.preferences.isOldLayout &&
            dataStudent.preferences.canYouAccessOld
          )
            window.location.href = '/aluno/dashboard';

          setVideoShow(dataStudent.dataStudent.videos[0]);
          if (!student) {
            setStudent(dataStudent);
          }
        }
      };
      loadStudentData();
    };

    validateUser();
  }, [location.pathname]);

  return (
    <ErrorBoundary>
      <NewStudentProviderContext.Provider
        value={{
          student: student!,
          setStudent: setStudent,
          accessedAs: accessedAs,
          setAccessedAs: setAccessedAs,
          loading: loading,
          setIsLoading: setIsLoading,
          isSidebarOpen: isSidebarOpen,
          setIsSidebarOpen: setIsSidebarOpen,
          themeSelected: themeSelected,
          setThemeSelected: setThemeSelected,
          unsignedContracts: unsignedContracts!,
          setUnsignedContracts: setUnsignedContracts,
          videoShow: videoShow!,
          setVideoShow: setVideoShow,
        }}
      >
        {children}
      </NewStudentProviderContext.Provider>
    </ErrorBoundary>
  );
}
