import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import * as S from './styles';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { IExamAnswerSheet } from '../../../..';
import { AlertBar, AlertWarning } from '../../../../../../Alert';
import TableBasic, { ITableData } from '../../../../../../Table';
import { formatDateHour } from '../../../../../../../../util/common/appUtil';
import { ButtonSimple } from '../../../../../../Buttons';
import AccessExamReview from '../../../../../AccessExamReview';
import FieldFormik from '../../../../../../FieldFormik';
import {
  INewStudentProvider,
  NewStudentProviderContext,
} from '../../../../../../ProviderNewStudent';
import QuillEditor from '../../../../../../InputTextQuill';
import AlertExamMinimumChar from '../../../../../AlertExamMinimumChar';
import { handleMaxWidth } from '../../../../../../../../util/newStudent';
import { useTheme } from 'styled-components';
import { CardLeftColor } from '../../../../../../Card';
import {
  IAnswerQuestion,
  IAnswers,
  IExam,
  IQuestion,
  myCoursesServiceNewStudent,
} from '../../../../../../../../services/newStudent/myCourses/myCourses.service';
import {
  typeExamMultipleChoice,
  typeExamSubjective,
} from '../../../../../../../../variables';

interface Props {
  data?: IExam;
  setData?: Dispatch<SetStateAction<IExam | undefined>>;
  setContentSelected: Dispatch<SetStateAction<string>>;
  setBlockedContent: Dispatch<SetStateAction<boolean>>;
}

export default function ExamContent({
  data,
  setData,
  setContentSelected,
  setBlockedContent,
}: Props) {
  const { setIsLoading } = useContext(
    NewStudentProviderContext,
  ) as INewStudentProvider;
  const defaultQuestion = {
    examType: 0,
    answers: [{ questionId: 0, alternativeId: null }],
  };

  const [tmpResponse, setTmpResponse] =
    useState<IAnswerQuestion>(defaultQuestion);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  const [questions, setQuestions] = useState<IAnswerQuestion>(defaultQuestion);
  const [inReview, setInReview] = useState(false);
  const [reviewed, setReviewed] = useState(false);
  const [modalIsReviewedOpen, setModalIsReviewedOpen] = useState(false);
  const [modalIsAtentionOpen, setModalIsAtentionOpen] = useState(false);

  const [countText, setCountText] = useState(0);

  const [recoveryExamText, setRecoveryExamText] = useState('');
  const minimumChar = 2600;

  const [dataTable, setDataTable] = useState<ITableData>({
    title: [],
    content: [],
  });
  const [dataTableMobile, setDataTableMobile] = useState<IExamAnswerSheet[]>();
  const theme = useTheme();

  /** Roda as informações para mostrar o gabarito  */
  useEffect(() => {
    setIsLoading(true);
    setInReview(false);
    setReviewed(false);
    setModalIsReviewedOpen(false);
    setModalIsAtentionOpen(false);
    setCountText(0);
    setRecoveryExamText('');

    if (
      data?.examType === +typeExamSubjective! &&
      data?.examQuestions[0].answer
    ) {
      setRecoveryExamText(String(data?.examQuestions[0].answer));
    }
    if (
      data?.examQuestions[0].teacherObservation &&
      data?.examQuestions[0].response
    ) {
      setRecoveryExamText(String(data?.examQuestions[0].response));
    }

    if (data?.examType === +typeExamMultipleChoice!) {
      setQuestions({
        examType: 0,
        answers:
          data?.examQuestions
            .flatMap((p) =>
              p.alternatives.map((alternative) => ({
                ...alternative,
                questionId: p.questionId,
              })),
            )
            .filter((filtered) => filtered.response)
            .map((mapped) => ({
              alternativeId: mapped.alternativeId,
              questionId: mapped.questionId,
            })) ?? [],
      });
    }
    try {
      if (data?.title?.trim().toLowerCase().includes('finali')) {
        setDataTable({
          title: [
            { item: <S.TitleTable>Questão</S.TitleTable>, align: 'center' },
            { item: <S.TitleTable>Resposta</S.TitleTable>, align: 'center' },
            { item: <S.TitleTable>Resultado</S.TitleTable>, align: 'center' },
            {
              item: <S.TitleTable>Respondido em</S.TitleTable>,
              align: 'center',
            },
          ],
          content: data?.examQuestions?.map((item) => ({
            question: {
              item: String(item.number).padStart(2, '0'),
              align: 'center',
            },
            response: {
              item: (
                <div dangerouslySetInnerHTML={{ __html: item.response }}></div>
              ),
              align: 'center',
              style: { maxWidth: '200px' },
            },
            isCorrect: {
              item: (
                <S.DivIsCorrect isCorrect={item.result}>
                  {item.question ? (
                    <CheckIcon fontSize="large" />
                  ) : (
                    <CloseIcon fontSize="large" />
                  )}
                  Resposta {item.result ? 'correta' : 'incorreta'}
                </S.DivIsCorrect>
              ),
              align: 'center',
            },
            responseDate: {
              item: formatDateHour(item.date),
              align: 'center',
            },
          })),
        });
        setDataTableMobile(
          data?.examQuestions?.map((item) => ({
            number: item.number,
            response: item.response,
            result: item.result,
            date: item.date,
          })),
        );
      }
    } catch (error) {
      throw new Error(error);
    }
    setIsLoading(false);
  }, [data, data?.title]);

  /** Fetch para salvar a prova e traz o resultado das questões */
  const handleSubmitExam = async () => {
    setIsLoading(true);
    try {
      const { result } = await myCoursesServiceNewStudent.finishExam(
        data?.examId,
        data?.examType,
      );

      if (result) {
        setBlockedContent(false);
        setContentSelected('400');
        setTimeout(() => {
          setBlockedContent(false);
          setContentSelected('3');
        }, 1000);

        setData && setData(undefined);
        setQuestions(defaultQuestion);
        setInReview(false);
        setReviewed(false);
      }
    } catch (error) {
      throw new Error(error);
    }
    setIsLoading(false);
  };

  /**
   * Faz a requisição de tempo em tempo para guardar a resposta do usuário
   * para que ele não perca a prova se der algum erro no computador, prova, internet, etc
   */
  useEffect(() => {
    if (data?.response) return;

    if (timer) {
      clearTimeout(timer);
    }
    const newTimerFetch = setTimeout(async () => {
      if (data?.examType === +typeExamMultipleChoice!) {
        if (tmpResponse.examType === 0) return;

        const { result } =
          await myCoursesServiceNewStudent.answerQuestion(tmpResponse);

        if (result) {
          setTmpResponse(defaultQuestion);
        }
      }

      if (data?.examType === +typeExamSubjective!) {
        await myCoursesServiceNewStudent.answerQuestion({
          examType: data?.examType,
          answers: [
            {
              questionId: data?.examQuestions?.[0].questionId,
              alternativeText: recoveryExamText,
            },
          ],
        });
      }

      if (data?.examType === +typeExamMultipleChoice!) {
        await myCoursesServiceNewStudent.answerQuestion(tmpResponse);
      }
    }, 500);

    setTimer(newTimerFetch);
    return () => {
      clearTimeout(newTimerFetch);
    };
  }, [tmpResponse.answers.length, questions.answers, recoveryExamText]);

  /** Função para guardar as resposta das questões */
  const handleAddResponseQuestion = (
    questionId: number,
    alternativeId: number | null,
  ) => {
    const updateAnswers = (oldAnswers, questionId, alternativeId) => {
      // Filtra respostas que não sejam da questão atual
      const filteredAnswers = oldAnswers.filter(
        (answer) => answer.questionId !== questionId && answer.questionId !== 0,
      );
      // Adiciona ou atualiza a resposta da questão atual
      return [...filteredAnswers, { questionId, alternativeId }];
    };

    setQuestions((old) => ({
      examType: data?.examType ?? 0,
      answers: updateAnswers(old.answers, questionId, alternativeId),
    }));

    setTmpResponse((old) => ({
      examType: data?.examType ?? 0,
      answers: updateAnswers(old.answers, questionId, alternativeId),
    }));
  };

  const validateQuestionFilter = (item: IQuestion) =>
    questions.answers.filter((idAnswer: IAnswers) => {
      return idAnswer?.questionId === item?.questionId;
    });

  const validateQuestion = (item, res) => {
    if (validateQuestionFilter(item)[0]?.alternativeId === res.alternativeId) {
      handleAddResponseQuestion(item.questionId, null);
    } else {
      handleAddResponseQuestion(item.questionId, res.alternativeId);
    }
  };

  return (
    <S.Container className="exam_content">
      {data?.title?.trim().toLowerCase().includes('finalizada') &&
        !data?.examQuestions[0].teacherObservation &&
        (handleMaxWidth(theme.size?.medium) ? (
          dataTableMobile?.map((item, index) => (
            <CardLeftColor
              key={index}
              color={item.result ? 'success' : 'danger'}
              heightSize="fullHeight"
            >
              <S.DivKeyValue>
                <S.KeyValue>
                  <S.Key>Questão: </S.Key>
                  <S.Value>{item.number}</S.Value>
                </S.KeyValue>
                <S.KeyValue>
                  <S.Key>Resposta: </S.Key>
                  <S.Value>{item.response}</S.Value>
                </S.KeyValue>
                <S.KeyValue>
                  <S.Key>Respondido em: </S.Key>
                  <S.Value>{formatDateHour(item.date)}</S.Value>
                </S.KeyValue>
                <S.Result color={item.result ? 'success' : 'danger'}>
                  {item.result ? 'Correto' : 'Incorreto'}
                </S.Result>
              </S.DivKeyValue>
            </CardLeftColor>
          ))
        ) : (
          <S.Content key={data?.title}>
            <S.Div>
              <S.DivName>
                <S.H4>Gabarito</S.H4>
              </S.DivName>
              <S.DivName>
                <S.H6>{data?.title}</S.H6>
              </S.DivName>
            </S.Div>

            {data.observation ? (
              <AlertBar color={'danger'} text={data.observation} noWarning />
            ) : (
              <></>
            )}

            <TableBasic data={dataTable} key={'table-basic'} />
          </S.Content>
        ))}
      {data?.examType === +typeExamMultipleChoice! && (
        <S.Content>
          <S.Div key={data?.title}>
            <S.DivName>
              <S.H4>Avaliação</S.H4>
            </S.DivName>
            <S.DivName>
              <S.H6>{data?.title}</S.H6>
            </S.DivName>
          </S.Div>
          {data?.examQuestions?.map((item: IQuestion, index: number) => (
            <S.QuestionDiv key={index}>
              <div>
                <S.H6Question inReview={inReview}>
                  Questão {index + 1}
                </S.H6Question>
                <S.H6Question inReview={inReview}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.question,
                    }}
                  ></div>
                </S.H6Question>
              </div>
              <div>
                {item.alternatives.map((res, key) => (
                  <S.DivResponse key={key} className={'question_' + key}>
                    <div style={{ height: '2rem' }}>
                      <input
                        type="radio"
                        style={{ cursor: !inReview ? 'pointer' : 'default' }}
                        disabled={inReview}
                        onClick={() => !inReview && validateQuestion(item, res)}
                        checked={
                          validateQuestionFilter(item)[0]?.alternativeId ===
                          res.alternativeId
                        }
                      />
                    </div>
                    <S.H6Response
                      onClick={() => !inReview && validateQuestion(item, res)}
                    >
                      <div
                        style={{
                          color:
                            validateQuestionFilter(item)[0]?.alternativeId ===
                              res.alternativeId && inReview
                              ? theme.colors?.text.info01
                              : '',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: res.alternative + ') ' + res.text,
                        }}
                      ></div>
                    </S.H6Response>
                  </S.DivResponse>
                ))}
              </div>
            </S.QuestionDiv>
          ))}
          {inReview ? (
            <>
              <S.Buttons>
                <ButtonSimple
                  color="danger"
                  widthSize="large"
                  onClick={() => {
                    setReviewed(false);
                    setInReview(false);
                  }}
                >
                  Corrigir
                </ButtonSimple>
                <ButtonSimple
                  color="success"
                  widthSize="large"
                  onClick={() => handleSubmitExam()}
                  disabled={!reviewed}
                >
                  Finalizar
                </ButtonSimple>
              </S.Buttons>
              <S.FieldDiv>
                <FieldFormik
                  name="readed"
                  placeholder="
                Estou ciente que após a finalização da prova não será possível responder ou alterar a prova novamente."
                  onChange={(e) => {
                    setReviewed(e.target.checked);
                  }}
                  type="checkbox"
                  checked={!!reviewed}
                  widthSize="fullWidth"
                />
              </S.FieldDiv>
            </>
          ) : (
            <>
              <S.ButtonExam>
                <ButtonSimple
                  color="success"
                  widthSize="large"
                  onClick={() => {
                    setModalIsReviewedOpen(true);
                  }}
                >
                  Confirmar
                </ButtonSimple>
              </S.ButtonExam>
              <AlertWarning title="Após confirmação,você será direcionado para a página de revisão" />
            </>
          )}
        </S.Content>
      )}
      {(data?.examType === +typeExamSubjective! ||
        data?.examQuestions[0].teacherObservation) && (
        <S.Content>
          <S.Div key={data?.title}>
            <S.DivName>
              <S.H4>Avaliação</S.H4>
            </S.DivName>
            <S.DivName>
              <S.H6>{data?.title}</S.H6>
            </S.DivName>
          </S.Div>
          {data.observation ? (
            <AlertBar color={'danger'} text={data.observation} noWarning />
          ) : (
            <></>
          )}
          {data?.examQuestions?.map((item, index) => (
            <S.QuestionDiv key={index} style={{ paddingBottom: '2rem' }}>
              <div>
                <S.H6Question inReview={inReview}>
                  Questão {index + 1}
                </S.H6Question>
                <S.H6Question inReview={inReview}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.question,
                    }}
                  ></div>
                </S.H6Question>
              </div>
              {countText > 0 && item?.question?.includes('lauda') && (
                <S.CountChar>
                  Quantidade de caracteres digitados: {countText}
                </S.CountChar>
              )}
              <QuillEditor
                text={recoveryExamText}
                setText={setRecoveryExamText}
                setCountChar={setCountText}
                disabled={
                  inReview || !!data?.examQuestions[0].teacherObservation
                }
              />
            </S.QuestionDiv>
          ))}
          {inReview ? (
            <>
              <S.Buttons>
                <ButtonSimple
                  color="danger"
                  widthSize="large"
                  onClick={() => {
                    setReviewed(false);
                    setInReview(false);
                  }}
                >
                  Corrigir
                </ButtonSimple>
                <ButtonSimple
                  color="success"
                  widthSize="large"
                  onClick={() => handleSubmitExam()}
                  disabled={!reviewed}
                >
                  Finalizar
                </ButtonSimple>
              </S.Buttons>
              <S.FieldDiv>
                <FieldFormik
                  name="readed"
                  placeholder="
                Estou ciente que após a finalização da prova não será possível responder ou alterar a prova novamente."
                  onChange={(e) => {
                    setReviewed(e.target.checked);
                  }}
                  type="checkbox"
                  checked={!!reviewed}
                  widthSize="fullWidth"
                />
              </S.FieldDiv>
            </>
          ) : (
            !data?.title?.trim().toLowerCase().includes('finalizada') && (
              <>
                <S.Button>
                  <ButtonSimple
                    color="success"
                    widthSize="large"
                    onClick={() => {
                      if (countText < minimumChar && countText > 0) {
                        setModalIsAtentionOpen(true);
                      } else {
                        setModalIsReviewedOpen(true);
                      }
                    }}
                  >
                    Confirmar
                  </ButtonSimple>
                </S.Button>
                <AlertWarning title="Após confirmação,você será direcionado para a página de revisão" />
              </>
            )
          )}
          <AlertExamMinimumChar
            count={countText}
            isOpen={modalIsAtentionOpen}
            setIsOpen={setModalIsAtentionOpen}
            minimum={minimumChar}
          />
        </S.Content>
      )}
      {(data?.examType === +typeExamSubjective! ||
        data?.examType === +typeExamMultipleChoice!) && (
        <>
          <AccessExamReview
            isOpen={modalIsReviewedOpen}
            setIsOpen={setModalIsReviewedOpen}
            setInReview={setInReview}
          />
        </>
      )}
    </S.Container>
  );
}
